import React, { useEffect, useState } from "react";
import CardAccordion from "../../../../../../../components/containers/CardAccordion";
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  Legend,
} from "recharts";
import { getTenYears } from "../../../../../../../utils/getTenyears";
import {
  currencyFormatter,
  currencyGraphFormatter,
} from "../../../../../../../utils/formatUtils";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ p: 1 }}>
        <Typography
          variant="caption"
          component={"p"}
          fontWeight={"bold"}
          align="center"
        >{`${payload[0].payload.name}`}</Typography>
        {/* <Typography variant="caption" component={"p"} textAlign={"center"}>
          Fecha: {new Date(payload[0].payload.date).toLocaleDateString()}
        </Typography> */}
        <div>
          {payload.map((pld, index) => (
            <Typography
              variant="caption"
              component={"p"}
              textAlign={"center"}
              key={`${pld.value}-${index}`}
            >
              {index === 0 ? "Capital " : "Distribución neta "}:
              {currencyFormatter.format(pld.value).split(".")[0]}
            </Typography>
          ))}
        </div>
      </Card>
    );
  }

  return null;
};

function DistributionCapitalGraph({ data, startProyectYear }) {
  console.log({ distGraph: data });
  const years = getTenYears(startProyectYear);

  const ages = [
    {
      value: "Todos",
      label: "Todos",
    },
    ...years.map((data) => {
      return {
        value: data,
        label: data.toString(),
      };
    }),
  ];

  const isSmallScreen = useMediaQuery("(min-width:600px)");

  const [myData, setMyData] = useState([]);
  const [selectedAge, setSelectedAge] = useState("Todos");

  const handleData = (myNewData, type) => {
    setMyData(myNewData);
  };

  useEffect(() => {
    if (data.length < 1) {
      return;
    }
    const newData = years.map((year) => {
      const cap = data
        .filter((select) => new Date(select.date).getFullYear() === year)
        .reduce(
          (acum, current) =>
            current["Capital aportado"] !== undefined ||
            current["Capital aportado"] != null
              ? current["Capital aportado"]
              : 0,
          0
        );
      const dist = data
        .filter((select) => new Date(select.date).getFullYear() === year)
        .reduce(
          (acum, current) =>
            acum >= current["Distribución neta acumulada"]
              ? acum
              : current["Distribución neta acumulada"],
          0
        );
      if (dist > 0) {
        return {
          name: year.toString(),
          "Capital aportado": cap > 0 ? cap : data[0]["Capital aportado"],
          "Distribución neta acumulada": dist ?? 0,
          date: new Date(year, 1, 1).toISOString(),
        };
      }
      const dist2 = data
        .filter((select) => new Date(select.date).getFullYear() === year - 1)
        .reduce(
          (acum, current) =>
            acum >= current["Distribución neta acumulada"]
              ? acum
              : current["Distribución neta acumulada"],
          0
        );
      return {
        name: year.toString(),
        "Capital aportado":
          cap > 0
            ? cap
            : data[0]["Capital aportado"] !== undefined ||
              data[0]["Capital aportado"] != null
            ? data[0]["Capital aportado"]
            : 0,
        "Distribución neta acumulada": dist2 ?? 0,
        date: new Date(year, 1, 1).toISOString(),
      };
    });
    handleData(newData.reverse(), selectedAge);
  }, [data]);

  const handleChange = (event) => {
    setSelectedAge(event.target.value);
    if (event.target.value === "Todos") {
      const newData = years.map((year) => {
        const cap = data
          .filter((select) => new Date(select.date).getFullYear() === year)
          .reduce((acum, current) => current["Capital aportado"], 0);
        const dist = data
          .filter((select) => new Date(select.date).getFullYear() === year)
          .reduce(
            (acum, current) =>
              acum >= current["Distribución neta acumulada"]
                ? acum
                : current["Distribución neta acumulada"],
            0
          );
        if (dist > 0) {
          return {
            name: year.toString(),
            "Capital aportado": cap > 0 ? cap : data[0]["Capital aportado"],
            "Distribución neta acumulada": dist ?? 0,
            date: new Date(year, 1, 1).toISOString(),
          };
        }
        const dist2 = data
          .filter((select) => new Date(select.date).getFullYear() === year - 1)
          .reduce(
            (acum, current) =>
              acum >= current["Distribución neta acumulada"]
                ? acum
                : current["Distribución neta acumulada"],
            0
          );
        return {
          name: year.toString(),
          "Capital aportado": cap > 0 ? cap : data[0]["Capital aportado"],
          "Distribución neta acumulada": dist2 ?? 0,
          date: new Date(year, 1, 1).toISOString(),
        };
      });
      return handleData(newData.reverse(), selectedAge);
    } else {
      const mySelectedData = data.filter((element) => {
        const date = new Date(element.date).getFullYear();
        return date === event.target.value;
      });
      handleData(mySelectedData, event.target.value);
    }
  };

  if (data.length === 0) {
    return (
      <CardAccordion
        title={"Historial distribución acumulada vs capital aportado"}
      >
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <Typography>No hay información disponible.</Typography>
        </Box>
      </CardAccordion>
    );
  }

  return (
    <CardAccordion
      title={"Historial distribución acumulada vs capital aportado"}
    >
      <Box>
        <Box display={"flex"} justifyContent={"flex-end"} px={3}>
          <Box sx={{ minWidth: 200 }}>
            <TextField
              select
              label="Fecha"
              disabled={false}
              value={selectedAge}
              onChange={handleChange}
              sx={{ py: 0 }}
            >
              {ages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        {myData.length < 1 ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={isSmallScreen ? 200 : 300}
          >
            <Typography>
              Actualmente, no se cuenta con información disponible.
            </Typography>
          </Box>
        ) : (
          <ResponsiveContainer height={isSmallScreen ? 200 : 300}>
            <AreaChart
              data={myData}
              margin={{
                top: 15,
                right: 25,
                left: 25,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient
                  id="colorDistributions"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="2%" stopColor="#3E6779" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#3E6779" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorCapital" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>

              <Area
                type="monotone"
                dataKey="Capital aportado"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorCapital)"
              />
              <Area
                type="monotone"
                dataKey="Distribución neta acumulada"
                stroke="#3E6779"
                fillOpacity={1}
                fill="url(#colorDistributions)"
              />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                position="middle"
                tickFormatter={(label) =>
                  `${currencyGraphFormatter(Number(label ?? 0))}`
                }
              />
              <Legend />
              <Tooltip content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        )}
        <Typography variant="caption" component={"p"} align="end" color="gray">
          Chart con información a la fecha de la transacción
        </Typography>
      </Box>
    </CardAccordion>
  );
}

export default DistributionCapitalGraph;
